const juryData = {
    2018: [
      {
        guild: 'Гильдия продюсеров',
        members: [
          'Александр Ткаченко — генеральный продюсер телеканала 1+1.',
          'Виталий Докаленко — креативный продюсер StarLightMedia.',
          'Наталья Влащенко — генеральный продюсер телеканала ZIK.',
        ],
      },
      {
        guild: 'Гильдия режиссеров и сценаристов',
        members: [
          'Сергей Лозница — режиссер-документалист, сценарист.',
          'Оксана Байрак — режиссер и сценарист.',
          'Дмитрий Томашпольский — режиссер, сценарист.',
        ],
      },
      {
        guild: 'Гильдия операторов, художников-постановщиков и художников по свету',
        members: [
          'Юрий Кузьменко — оператор-постановщик.',
          'Анна Валентинова — художник-постановщик.',
          'Виктор Придувалов — оператор, режиссер видеоклипов.',
        ],
      },
      {
        guild: 'Гильдия информационного, спортивного и общественно-социального вещания',
        members: [
          'Андрей Куликов — журналист, телеведущий.',
          'Алла Мазур — ведущая новостей на телеканале 1+1.',
          'Александр Денисов — спортивный комментатор.',
        ],
      },
      {
        guild: 'Гильдия детского, музыкального и тематического производства',
        members: [
          'Татьяна Рамус — ведущая и продюсер детских программ.',
          'Потап (Алексей Потапенко) — продюсер музыкальных проектов.',
          'Марина Поплавская — ведущая и сценарист детских программ.',
        ],
      },
      {
        guild: 'Гильдия телевизионного дизайна и промо',
        members: [
          'Виктория Скипальская — дизайнер телеканала СТБ.',
          'Иван Дорн — композитор, креативный копирайтер.',
          'Дмитрий Дроздов — специалист по промо.',
        ],
      },
    ],
    2019: [
      {
        guild: 'Гильдия продюсеров',
        members: [
          'Елена Канишевская — генеральный продюсер ICTV.',
          'Владимир Зеленский — продюсер, сценарист (до президентства).',
          'Дмитрий Бондаренко — продюсер проекта «Україна має талант».',
        ],
      },
      {
        guild: 'Гильдия режиссеров и сценаристов',
        members: [
          'Михаил Ильенко — режиссер, сценарист.',
          'Ирина Костюк — сценарист, продюсер.',
          'Андрей Дончик — режиссер.',
        ],
      },
      {
        guild: 'Гильдия операторов, художников-постановщиков и художников по свету',
        members: [
          'Валентин Васянович — оператор-постановщик, режиссер.',
          'Андрей Севбо — художник-постановщик.',
          'Сергей Михальчук — оператор.',
        ],
      },
      {
        guild: 'Гильдия информационного, спортивного и общественно-социального вещания',
        members: [
          'Дмитрий Гордон — журналист, телеведущий.',
          'Мария Синицына — ведущая новостей на телеканале «Украина».',
          'Роман Скрыпин — журналист.',
        ],
      },
      {
        guild: 'Гильдия детского, музыкального и тематического производства',
        members: [
          'Юлия Санина — певица, телеведущая.',
          'Валерий Харчишин — продюсер музыкальных проектов.',
          'Игорь Кондратюк — ведущий и продюсер.',
        ],
      },
      {
        guild: 'Гильдия телевизионного дизайна и промо',
        members: [
          'Антон Носик — промо-специалист.',
          'Евгений Филатов — музыкальный продюсер.',
          'Ольга Навроцкая — дизайнер, стилист.',
        ],
      },
    ],
    2020: [
      {
        guild: 'Гильдия продюсеров',
        members: [
          'Оксана Кириллова - Продюсер телеканала «Россия 1»',
          'Сурен Джулакян — продюсер телеканала СТБ.',
          'Виктория Забулонская — продюсер телеканала «Интер».',
        ],
      },
      {
        guild: 'Гильдия режиссеров и сценаристов',
        members: [
          'Наталья Ворожбит — сценарист, режиссер.',
          'Алиса Виноградова — режиссер.',
          'Елена Лядова — сценарист.',
        ],
      },
      {
        guild: 'Гильдия операторов, художников-постановщиков и художников по свету',
        members: [
          'Владимир Иванов — оператор-постановщик.',
          'Наталья Коваленко — художник-постановщик.',
          'Андрей Шевченко — оператор.',
        ],
      },
      {
        guild: 'Гильдия информационного, спортивного и общественно-социального вещания',
        members: [
          'Евгений Киселев — журналист, телеведущий.',
          'Лариса Губина — ведущая новостей на канале ICTV.',
          'Алексей Мочанов — спортивный комментатор.',
        ],
      },
      {
        guild: 'Гильдия детского, музыкального и тематического производства',
        members: [
          'Алена Мозговая — продюсер музыкальных проектов.',
          'Олег Скрипка — музыкант, продюсер.',
          'Алена Бурима — сценарист детских программ.',
        ],
      },
      {
        guild: 'Гильдия телевизионного дизайна и промо',
        members: [
          'Иван Мацкевич — дизайнер телеканала 1+1.',
          'Светлана Лобода — певица, стилист.',
          'Виктор Павлик — композитор, креативный копирайтер.',
        ],
      },
    ],
    2021: [
      {
        guild: 'Гильдия продюсеров',
        members: [
          'Андрей Алферов — генеральный продюсер канала «Интер».',
          'Владимир Котляров — креативный продюсер.',
          'Светлана Тарабарова — продюсер.',
        ],
      },
      {
        guild: 'Гильдия режиссеров и сценаристов',
        members: [
          'Роман Балаян — режиссер.',
          'Виктор Шамиров — режиссер.',
          'Владимир Тихий — режиссер, сценарист.',
        ],
      },
      {
        guild: 'Гильдия операторов, художников-постановщиков и художников по свету',
        members: [
          'Сергей Боклан — оператор-постановщик.',
          'Алексей Степанов — художник-постановщик.',
          'Виктор Гонтарук — оператор.',
        ],
      },
      {
        guild: 'Гильдия информационного, спортивного и общественно-социального вещания',
        members: [
          'Андрей Куликов — журналист, телеведущий.',
          'Елена Ландер — ведущая новостей на канале «Украина».',
          'Александр Денисов — спортивный комментатор.',
        ],
      },
      {
        guild: 'Гильдия детского, музыкального и тематического производства',
        members: [
          'Ирина Билык — певица, продюсер музыкальных проектов.',
          'Андрей Данилко — продюсер.',
          'Наталья Могилевская — продюсер детских программ.',
        ],
      },
      {
        guild: 'Гильдия телевизионного дизайна и промо',
        members: [
          'Денис Козловский — промо-специалист.',
          'Валерий Меладзе — композитор.',
          'Екатерина Осадчая — дизайнер.',
        ],
      },
    ],
    2022: [
      {
        guild: 'Гильдия продюсеров',
        members: [
          'Дмитрий Исаенко — генеральный продюсер.',
          'Ольга Захарова — креативный продюсер.',
          'Сергей Лавренюк — продюсер.',
        ],
      },
      {
        guild: 'Гильдия режиссеров и сценаристов',
        members: [
          'Александр Сокуров — режиссер.',
          'Юрий Быков — сценарист, режиссер.',
          'Алина Гросу — сценарист.',
        ],
      },
      {
        guild: 'Гильдия операторов, художников-постановщиков и художников по свету',
        members: [
          'Павел Негрич — оператор-постановщик.',
          'Ольга Никитина — художник-постановщик.',
          'Максим Дроздов — оператор.',
        ],
      },
      {
        guild: 'Гильдия информационного, спортивного и общественно-социального вещания',
        members: [
          'Наталья Мосейчук — ведущая новостей на канале 1+1.',
          'Алексей Суханов — журналист.',
          'Сергей Долгов — спортивный комментатор.',
        ],
      },
      {
        guild: 'Гильдия детского, музыкального и тематического производства',
        members: [
          'Марина Захарова — продюсер детских программ.',
          'Сергей Бабкин — музыкант, продюсер.',
          'Тина Кароль — продюсер.',
        ],
      },
      {
        guild: 'Гильдия телевизионного дизайна и промо',
        members: [
          'Дмитрий Ларин — дизайнер, стилист.',
          'Анна Седокова — композитор.',
          'Александр Пономарев — креативный копирайтер.',
        ],
      },
    ],
    2023: [
      {
        guild: 'Гильдия продюсеров',
        members: [
          'Наталья Влащенко — генеральный продюсер телеканала ZIK.',
          'Юрий Артеменко — продюсер, сценарист.',
          'Алексей Гончаренко — продюсер.',
        ],
      },
      {
        guild: 'Гильдия режиссеров и сценаристов',
        members: [
          'Андрей Загданский — режиссер.',
          'Юлия Степаненко — сценарист.',
          'Александр Шапиро — режиссер, сценарист.',
        ],
      },
      {
        guild: 'Гильдия операторов, художников-постановщиков и художников по свету',
        members: [
          'Дмитрий Михалков — оператор-постановщик.',
          'Ирина Белоцерковская — художник-постановщик.',
          'Павел Костюков — оператор.',
        ],
      },
      {
        guild: 'Гильдия информационного, спортивного и общественно-социального вещания',
        members: [
          'Татьяна Даниленко — ведущая новостей.',
          'Михаил Саакашвили — политический комментатор.',
          'Евгений Харьковщенко — спортивный комментатор.',
        ],
      },
      {
        guild: 'Гильдия детского, музыкального и тематического производства',
        members: [
          'Настя Каменских — продюсер музыкальных проектов.',
          'Владимир Зеленский — продюсер.',
          'Надежда Мейхер — ведущая детских программ.',
        ],
      },
      {
        guild: 'Гильдия телевизионного дизайна и промо',
        members: [
          'Дмитрий Коляденко — дизайнер.',
          'Артем Пивоваров — композитор.',
          'Ирина Билык — креативный копирайтер.',
        ],
      },
    ],
    2024: [
      {
        guild: 'Гильдия продюсеров',
        members: [
          'Виктор Мирский — генеральный продюсер StarLightMedia.',
          'Ольга Захарова — креативный продюсер.',
          'Светлана Долгополова — продюсер телеканала ICTV.',
        ],
      },
      {
        guild: 'Гильдия режиссеров и сценаристов',
        members: [
          'Юрий Романенко — режиссер.',
          'Ольга Дергунова — сценарист.',
          'Максим Поляков — режиссер, сценарист.',
        ],
      },
      {
        guild: 'Гильдия операторов, художников-постановщиков и художников по свету',
        members: [
          'Артем Шепелев — оператор-постановщик.',
          'Наталья Самойленко — художник-постановщик.',
          'Константин Егоров — оператор.',
        ],
      },
      {
        guild: 'Гильдия информационного, спортивного и общественно-социального вещания',
        members: [
          'Егор Соболев — журналист, телеведущий.',
          'Светлана Лобода — ведущая новостей.',
          'Александр Пономарев — спортивный комментатор.',
        ],
      },
      {
        guild: 'Гильдия детского, музыкального и тематического производства',
        members: [
          'Тина Кароль — продюсер музыкальных проектов.',
          'Потап (Алексей Потапенко) — продюсер.',
          'Евгений Кошевой — продюсер детских программ.',
        ],
      },
      {
        guild: 'Гильдия телевизионного дизайна и промо',
        members: [
          'Александр Березняков — дизайнер.',
          'Олег Винник — композитор.',
          'Юлия Санина — стилист.',
        ],
      },
    ],
  };

  export default juryData;