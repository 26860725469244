import React, { useState, useEffect } from 'react';
import HeaderImage from './HeaderImage';
import imageUrl from '../img/site-bg.jpg';
import aboutImg from '../img/about.jpg';

const About = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const testimonials = [
    {
      quote: '«С момента своего учреждения в 2000 году "Телетриумф" стал главным символом признания достижений в украинской телеиндустрии.»',
      author: 'Национальный совет Украины',
      company: 'по вопросам телевидения и радиовещания',
    },
    {
      quote: '«"Телетриумф" продолжает развиваться, привлекая внимание не только национальных, но и международных экспертов.»',
      author: 'Эксперты телеиндустрии',
      company: 'Международные и национальные эксперты',
    },
    {
      quote: '«Премия остается главной телевизионной наградой Украины, символизируя уважение и высокие стандарты профессионализма в телеиндустрии.»',
      author: 'Телеэксперты Украины',
      company: 'Национальная телеиндустрия',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 15000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <>
      <HeaderImage imageUrl={imageUrl} altText="Описание изображения">
        <h1>Национальная телевизионная премия "Телетриумф"</h1>
      </HeaderImage>
      <div className="award-info">
      <div class="about-company">
          <div class="left-column">
            <h3>О премии</h3>
            <h1>Мы создаем и превращаем мечты в реальность</h1>
          </div>
          <div class="right-column">
            <img src={aboutImg} alt="aboutImg" />
            <p>
              <strong>Добро пожаловать на сайт Национальной телевизионной премии "Телетриумф"! </strong>
            </p>
            <p>
            С момента своего учреждения в 2000 году Национальным советом Украины по вопросам телевидения и радиовещания, "Телетриумф" стал главным символом признания достижений в украинской телеиндустрии. Премия отмечает лучших профессионалов и выдающиеся работы в области телевидения, подчеркивая важность и значимость творчества в современном медиапространстве.
            </p>
          </div>
        </div>
        <h1 className="award-title">Главная награда страны</h1>
        <p className="award-text">
          Премия "Телетриумф" является главной телевизионной наградой Украины, символизируя уважение и высокие стандарты профессионализма в телеиндустрии. Каждый год премия собирает лучших представителей телевизионного искусства, чтобы отметить их вклад в развитие телевидения, поощрить творческие достижения и профессионализм, а также вдохновить новые поколения мастеров телеэкрана.
        </p>
        <h1 className="award-title">Мы приглашаем лучших</h1>
        <p className="award-text">
          Для участия в Премии "Телетриумф" приглашаются лучшие телевизионные проекты, продюсеры, режиссеры, сценаристы, операторы, телеведущие и другие профессионалы.
        </p>
        <p className="award-text">
          Национальная телевизионная премия "Телетриумф" проводится ежегодно весной и охватывает работы, вышедшие в эфир с 1 сентября предыдущего года по 31 декабря текущего года. Основные этапы конкурса включают прием заявок, голосование и объявление номинантов, что происходит в течение двух недель.
        </p>
        </div>
        <section className="testimonials-section">
        <h3 className="testimonials-title">О нас</h3>
        <div className="testimonial">
          <p className="testimonial-quote">{testimonials[currentTestimonial].quote}</p>
          <p className="testimonial-author">{testimonials[currentTestimonial].author}</p>
          <p className="testimonial-company">{testimonials[currentTestimonial].company}</p>
        </div>
        <div className="testimonial-dots">
          {testimonials.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentTestimonial ? 'active' : ''}`}
              onClick={() => setCurrentTestimonial(index)}
            ></span>
          ))}
        </div>
      </section>
      <div className="award-info">
        <p className="award-text">
          Номинанты "Телетриумфа" оцениваются по таким критериям, как новизна, оригинальность, профессионализм и общее впечатление от работы. Процедура голосования разделена на два этапа: онлайн-голосование и открытое обсуждение на собрании гильдии, что позволяет всесторонне оценить представленные проекты.
        </p>
        <p className="award-text">
          У премии также есть цифровые номинации, которые оценивают работу с новыми технологиями, такими как 360-градусное видео и виртуальная реальность, что подчеркивает стремление премии идти в ногу со временем.
        </p>
        <p className="award-text">
        <h1 className="award-title">Значимость премии "Телетриумф" для работников телевидения</h1>
          Премия "Телетриумф" не просто признание — это знак высочайшего профессионализма в телеиндустрии. Для работников телевидения получение этой награды является свидетельством того, что их труд был признан и высоко оценен на национальном уровне. Премия служит важным стимулом для дальнейшего профессионального роста и творчества, мотивируя специалистов стремиться к новым высотам и инновациям в своей работе.
        </p>
        <p className="award-text">
          Для работников телевидения получение "Телетриумфа" — это вершина карьеры, подтверждение того, что их усилия и вклад в развитие телевидения достигли международного уровня. Премия открывает перед ее обладателями новые горизонты, позволяя заявить о себе не только в Украине, но и на мировой арене. Престиж "Телетриумфа" таков, что многие мастера телеэкрана годами усердно работают, чтобы заслужить эту почетную награду.
        </p>
        <p className="award-text">
          Ежегодно "Телетриумф" привлекает внимание лучших представителей телеиндустрии, которые видят в этой премии не только знак высокого профессионализма, но и возможность войти в историю украинского телевидения. Мечта о "Телетриумфе" вдохновляет работников телевидения на создание новых, ярких и уникальных проектов, которые не только радуют зрителей, но и задают новые стандарты качества в индустрии.
        </p>
        <h1 className="award-title">Международное признание победителей</h1>
        <p className="award-text">
          Премия "Телетриумф" не только признана на национальном уровне, но и получила широкое признание за пределами Украины. Победители этой премии становятся известными и уважаемыми не только в своей стране, но и за ее пределами, что открывает перед ними новые возможности для карьерного роста и сотрудничества на международной арене. Получение "Телетриумфа" означает, что ваш труд признан как один из лучших в мире, и это помогает укрепить позиции украинской телеиндустрии на глобальной арене.
        </p>
        <p className="award-text">
          "Телетриумф" — это не только престижная награда, но и возможность поделиться своим талантом с миллионами зрителей по всей стране, получить признание среди коллег и профессионалов индустрии. Присоединяйтесь к нашей миссии по развитию телевидения и станьте частью истории "Телетриумфа"! Ваш вклад может вдохновить будущее поколение телевизионных мастеров и оставить неизгладимый след в истории украинского телевидения.
        </p>
        <p className="award-text">
          Если ваш проект вышел в эфир в течение последнего календарного года и вы хотите, чтобы он был оценен ведущими специалистами индустрии, подача заявки на участие в премии — это ваш шанс получить заслуженное признание. Узнать больше о правилах участия, номинациях и критериях оценки можно в соответствующих разделах нашего сайта.
        </p>
      </div>
    </>
  );
};

export default About;