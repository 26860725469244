const winnersData = {
    2018: [
      { category: 'Лучший телевизионный фильм', winner: '"Киборги" (телеканал 1+1)' },
      { category: 'Лучший драматический сериал', winner: '"Школа" (телеканал 1+1)' },
      { category: 'Лучший комедийный сериал', winner: '"Останній москаль" (телеканал 1+1)' },
      { category: 'Лучшая информационная программа', winner: '"ТСН" (телеканал 1+1)' },
      { category: 'Лучшее ток-шоу', winner: '"Свобода слова" (телеканал ICTV)' },
      { category: 'Лучший ведущий информационной программы', winner: 'Алла Мазур ("ТСН", телеканал 1+1)' },
      { category: 'Лучший ведущий развлекательной программы', winner: 'Сергей Притула ("Вар\'яти", телеканал Новий канал)' },
      { category: 'Лучший режиссер', winner: 'Сергей Лозница ("Киборги")' },
      { category: 'Лучший сценарист', winner: 'Валентин Васянович ("Киборги")' },
      { category: 'Лучшая операторская работа', winner: 'Валентин Васянович ("Киборги")' },
      { category: 'Лучшая детская программа', winner: '"Снегири и Елка" (телеканал Интер)' },
      { category: 'Лучшая музыкальная программа', winner: '"Україна має талант" (телеканал СТБ)' },
      { category: 'Лучший телевизионный дизайн и промо', winner: '"Україна має талант" (телеканал СТБ)' },
      { category: 'Лучший документальный проект', winner: '"Чернобыль. Зона отчуждения" (телеканал НЛО TV)' },
    ],
    2019: [
      { category: 'Лучший телевизионный фильм', winner: '"Черный ворон" (телеканал 1+1)' },
      { category: 'Лучший драматический сериал', winner: '"Солнечный листопад" (Телеканал ТРК Украина)' },
      { category: 'Лучший комедийный сериал', winner: '"Сваты 7" (телеканал Интер)' },
      { category: 'Лучшая информационная программа', winner: '"Факты" (телеканал ICTV)' },
      { category: 'Лучшее ток-шоу', winner: '"Свобода слова" (телеканал ICTV)' },
      { category: 'Лучшее развлекательное шоу', winner: '“Битва экстрасенсов” (телеканал СТБ)' },
      { category: 'Лучший ведущий информационной программы', winner: 'Андрей Куликов ("Свобода слова", телеканал ICTV)' },
      { category: 'Лучший ведущий развлекательной программы', winner: 'Владимир Зеленский ("Вечерний квартал", телеканал 1+1)' },
      { category: 'Лучший режиссер', winner: 'Алиса Виноградова ("Слежка")' },
      { category: 'Лучший креативный продюсер', winner: 'Сурен Джулакян ("Битва Экстрасенсов", телеканал СТБ)' },
      { category: 'Лучший сценарист', winner: 'Наталья Ворожбит ("Черный ворон")' },
      { category: 'Лучшая операторская работа', winner: 'Сергей Михальчук ("Черный ворон")' },
      { category: 'Лучшая детская программа', winner: '"Маленькие гиганты" (телеканал Интер)' },
      { category: 'Лучшая музыкальная программа', winner: '"Голос країни" (телеканал 1+1)' },
      { category: 'Лучший телевизионный дизайн и промо', winner: '"Танцы со звездами" (телеканал 1+1)' },
      { category: 'Лучший документальный проект', winner: '"Битва за Киев" (телеканал ICTV)' },
    ],
    2020: [
      { category: 'Лучший телевизионный фильм', winner: '"Крепостная" (телеканал СТБ)' },
      { category: 'Лучший драматический сериал', winner: '"Папик" (телеканал 1+1)' },
      { category: 'Лучший комедийный сериал', winner: '"Слуга народа" (телеканал 1+1)' },
      { category: 'Лучшая информационная программа', winner: '"ТСН" (телеканал 1+1)' },
      { category: 'Лучшее ток-шоу', winner: '"Говорит Украина" (телеканал Украина)' },
      { category: 'Лучший ведущий информационной программы', winner: 'Наталья Мосейчук ("ТСН", телеканал 1+1)' },
      { category: 'Лучший ведущий развлекательной программы', winner: 'Сергей Притула ("Вар\'яти", телеканал Новий канал)' },
      { category: 'Лучший режиссер', winner: 'Виктор Шамиров ("Черный ворон")' },
      { category: 'Лучший сценарист', winner: 'Александр Соколовский ("Папик")' },
      { category: 'Лучшая операторская работа', winner: 'Владимир Иванов ("Папик")' },
      { category: 'Лучшая детская программа', winner: '"Круче всех" (телеканал Интер)' },
      { category: 'Лучшая музыкальная программа', winner: '"Танцы со звездами" (телеканал 1+1)' },
      { category: 'Лучший телевизионный дизайн и промо', winner: '"Крепостная" (телеканал СТБ)' },
      { category: 'Лучший документальный проект', winner: '"Чернобыль. Зона отчуждения" (телеканал НЛО TV)' },
      { category: 'Лучший креативный продюсер', winner: 'Алексей Гончаренко ("Танцы со звездами")' },
    ],
    2021: [
      { category: 'Лучший телевизионный фильм', winner: '"Захар Беркут" (телеканал 1+1)' },
      { category: 'Лучший драматический сериал', winner: '"Великая" (телеканал Украина)' },
      { category: 'Лучший комедийный сериал', winner: '"Люся Интерн" (телеканал Интер)' },
      { category: 'Лучшая информационная программа', winner: '"Украина 24" (телеканал Украина 24)' },
      { category: 'Лучшее ток-шоу', winner: '"Шустер LIVE" (телеканал Украина)' },
      { category: 'Лучший ведущий информационной программы', winner: 'Дмитрий Гордон ("Гордон", телеканал Украина)' },
      { category: 'Лучший ведущий развлекательной программы', winner: 'Сергей Притула ("Вар\'яти", телеканал Новий канал)' },
      { category: 'Лучший режиссер', winner: 'Юлия Степаненко ("Захар Беркут")' },
      { category: 'Лучший сценарист', winner: 'Юлия Степаненко ("Захар Беркут")' },
      { category: 'Лучшая операторская работа', winner: 'Сергей Боклан ("Захар Беркут")' },
      { category: 'Лучшая детская программа', winner: '"Легенды Украины" (телеканал НЛО TV)' },
      { category: 'Лучшая музыкальная программа', winner: '"Голос країни" (телеканал 1+1)' },
      { category: 'Лучший телевизионный дизайн и промо', winner: '"Люся Интерн" (телеканал Интер)' },
      { category: 'Лучший документальный проект', winner: '"Мирный 21" (телеканал ICTV)' },
      { category: 'Лучший креативный продюсер', winner: 'Елена Канишевская ("Голос країни")' },
    ],
    2022: [
      { category: 'Лучший телевизионный фильм', winner: '"Сквозь черное стекло" (телеканал 1+1)' },
      { category: 'Лучший драматический сериал', winner: '"Великая" (телеканал Украина)' },
      { category: 'Лучший комедийный сериал', winner: '"Свати" (телеканал Интер)' },
      { category: 'Лучшая информационная программа', winner: '"Факты" (телеканал ICTV)' },
      { category: 'Лучшее ток-шоу', winner: '"Голос країни" (телеканал 1+1)' },
      { category: 'Лучший ведущий информационной программы', winner: 'Наталья Мосейчук ("ТСН", телеканал 1+1)' },
      { category: 'Лучший ведущий развлекательной программы', winner: 'Сергей Притула ("Вар\'яти", телеканал Новий канал)' },
      { category: 'Лучший режиссер', winner: 'Александр Сокуров ("Сквозь черное стекло")' },
      { category: 'Лучший сценарист', winner: 'Наталья Ворожбит ("Великая")' },
      { category: 'Лучшая операторская работа', winner: 'Павел Негрич ("Великая")' },
      { category: 'Лучшая детская программа', winner: '"Круче всех" (телеканал Интер)' },
      { category: 'Лучшая музыкальная программа', winner: '"Танцы со звездами" (телеканал 1+1)' },
      { category: 'Лучший телевизионный дизайн и промо', winner: '"Великая" (телеканал Украина)' },
      { category: 'Лучший документальный проект', winner: '"Мирный 21" (телеканал ICTV)' },
      { category: 'Лучший креативный продюсер', winner: 'Алексей Гончаренко ("Танцы со звездами")' },
    ],
    2023: [
      { category: 'Лучший телевизионный фильм', winner: '"Степные волки" (телеканал СТБ)' },
      { category: 'Лучший драматический сериал', winner: '"Папик 2" (телеканал 1+1)' },
      { category: 'Лучший комедийный сериал', winner: '"Казаки. Абсолютно лживая история" (телеканал Интер)' },
      { category: 'Лучшая информационная программа', winner: '"ТСН" (телеканал 1+1)' },
      { category: 'Лучшее ток-шоу', winner: '"Право на владу" (телеканал 1+1)' },
      { category: 'Лучший ведущий информационной программы', winner: 'Мария Синицына ("Украина 24")' },
      { category: 'Лучший ведущий развлекательной программы', winner: 'Сергей Притула ("Вар\'яти", телеканал Новий канал)' },
      { category: 'Лучший режиссер', winner: 'Александр Шапиро ("Степные волки")' },
      { category: 'Лучший сценарист', winner: 'Александр Шапиро ("Степные волки")' },
      { category: 'Лучшая операторская работа', winner: 'Дмитрий Михалков ("Степные волки")' },
      { category: 'Лучшая детская программа', winner: '"Круче всех" (телеканал Интер)' },
      { category: 'Лучшая музыкальная программа', winner: '"МастерШеф" (телеканал СТБ)' },
      { category: 'Лучший телевизионный дизайн и промо', winner: '"Казаки. Абсолютно лживая история" (телеканал Интер)' },
      { category: 'Лучший документальный проект', winner: '"Ревизор" (телеканал Новый)' },
      { category: 'Лучший креативный продюсер', winner: 'Елена Канишевская ("МастерШеф")' },
    ],
    2024: [
      { category: 'Лучший телевизионный фильм', winner: '"Обратная сторона луны" (телеканал Украина)' },
      { category: 'Лучший драматический сериал', winner: '"Второе дыхание" (телеканал 1+1)' },
      { category: 'Лучший комедийный сериал', winner: '"Сваты 8" (телеканал Интер)' },
      { category: 'Лучшая информационная программа', winner: '"Факты" (телеканал ICTV)' },
      { category: 'Лучшее ток-шоу', winner: '"Х-фактор" (телеканал СТБ)' },
      { category: 'Лучший ведущий информационной программы', winner: 'Светлана Лобода ("Факты", телеканал ICTV)' },
      { category: 'Лучший ведущий развлекательной программы', winner: 'Сергей Притула ("Вар\'яти", телеканал Новий канал)' },
      { category: 'Лучший режиссер', winner: 'Юрий Романенко ("Второе дыхание")' },
      { category: 'Лучший сценарист', winner: 'Юрий Романенко ("Второе дыхание")' },
      { category: 'Лучшая операторская работа', winner: 'Артем Шепелев ("Обратная сторона луны")' },
      { category: 'Лучшая детская программа', winner: '"Вундеркинды" (телеканал Украина)' },
      { category: 'Лучшая музыкальная программа', winner: '"Голос країни" (телеканал 1+1)' },
      { category: 'Лучший телевизионный дизайн и промо', winner: '"Второе дыхание" (телеканал 1+1)' },
      { category: 'Лучший документальный проект', winner: '"Мирный 21" (телеканал ICTV)' },
      { category: 'Лучший креативный продюсер', winner: 'Игорь Кондратюк ("Голос країни")' },
    ]

  };

export default winnersData;