import React from 'react';
import HeaderImage from './HeaderImage';
import imageUrl from '../img/site-bg.jpg';

const PositionPage = () => {
  return (
    <>
    <HeaderImage imageUrl={imageUrl} altText="Описание изображения">
    <h1>Национальная телевизионная премия "Телетриумф"</h1>
  </HeaderImage>
    <div className="position-page">
      <h1 className="position-title">Положение о Национальной телевизионной премии "Телетриумф"</h1>
      <div className="position-content">
        <h2>1. Общие положения</h2>
        <p>1.1. Национальная телевизионная премия "Телетриумф" (далее — Премия) является ежегодным конкурсом, организованным Национальным советом Украины по вопросам телевидения и радиовещания в сотрудничестве с Украинской телевизионной академией. Премия направлена на признание и поощрение лучших профессионалов и проектов в украинской телеиндустрии.</p>
        <p>1.2. Премия проводится с целью поддержки и развития национального телевидения, повышения уровня профессионализма в отрасли и популяризации лучших телевизионных программ, фильмов и других проектов.</p>
        <p>1.3. Участниками Премии могут быть лучшие телевизионные проекты, продюсеры, режиссеры, сценаристы, операторы, телеведущие и другие профессионалы.</p>

        <h2>2. Организация и проведение Премии</h2>
        <p>2.1. Организацию и проведение Премии осуществляет Организационный комитет (далее — Оргкомитет), который формируется Национальным советом Украины по вопросам телевидения и радиовещания.</p>
        <p>2.2. Оргкомитет утверждает список номинаций, регламент проведения конкурса, сроки подачи заявок, процедуры голосования и награждения победителей.</p>
        <p>2.3. Оргкомитет несет ответственность за обеспечение прозрачности и объективности проведения Премии, соблюдение всех условий и правил конкурса.</p>
        <p>2.4. В состав Оргкомитета входят представители Национального совета Украины по вопросам телевидения и радиовещания, Украинской телевизионной академии, а также другие авторитетные представители телеиндустрии.</p>
        <p>2.5. Прием заявок на участие в Национальной телевизионной премии "Телетриумф" осуществляется ежегодно с 20 февраля по 7 марта.</p>
        <p>2.6. Обработка заявок оргкомитетом проходит ежегодно с 8 марта по 14 марта.</p>
        <p>2.7. Голосование за выбор победителей проходит ежегодно с 15 марта по 29 марта.</p>
        <p>2.8. Обработка результатов голосования аудиторами премии проходит ежегодно с 30 марта по 4 апреля.</p>
        <p>2.9. Оглашение номинантов премии "Телетриумф" проводится ежегодно 5 апреля.</p>

        <h2>3. Номинации</h2>
        <p>3.1. Премия "Телетриумф" проводится по следующим номинациям:</p>
        <ul>
          <li>Лучший телевизионный фильм</li>
          <li>Лучший драматический сериал</li>
          <li>Лучший комедийный сериал</li>
          <li>Лучшая информационная программа</li>
          <li>Лучшее ток-шоу</li>
          <li>Лучшее развлекательное шоу</li>
          <li>Лучший ведущий информационной программы</li>
          <li>Лучший ведущий развлекательной программы</li>
          <li>Лучший режиссер</li>
          <li>Лучший продюсер</li>
          <li>Лучший сценарист</li>
          <li>Лучшая операторская работа</li>
          <li>Лучшая детская программа</li>
          <li>Лучшая музыкальная программа</li>
          <li>Лучший телевизионный дизайн и промо</li>
          <li>Лучший документальный проект</li>
        </ul>
        <p>3.2. Оргкомитет может ежегодно пересматривать и расширять список номинаций в зависимости от развития телеиндустрии и предложений членов Украинской телевизионной академии.</p>

        <h2>4. Участие в конкурсе</h2>
        <p>4.1. Для участия в Премии заявки могут подаваться как индивидуальными участниками, так и телевизионными компаниями.</p>
        <p>4.2. Заявки на участие принимаются в электронном формате через официальный сайт Премии в период, установленный Оргкомитетом.</p>
        <p>4.3. Каждый участник или компания имеет право подать несколько заявок на различные номинации, при этом каждая заявка должна соответствовать условиям и требованиям конкретной номинации.</p>
        <p>4.4. Заявки, поданные после установленного срока, к участию в Премии не допускаются.</p>
        <p>4.5. В случае несоответствия заявки установленным критериям, Оргкомитет имеет право отклонить ее от участия в конкурсе.</p>

        <h2>5. Процедура оценки и голосования</h2>
        <p>5.1. Оценка заявок проводится членами жюри, которые распределяются по профессиональным гильдиям: продюсеров, режиссеров, сценаристов, операторов, художников-постановщиков, художников по свету, информационного, спортивного и общественно-социального вещания, детского, музыкального и тематического производства, телевизионного дизайна и промо.</p>
        <p>5.2. Члены жюри оценивают заявки в два этапа:</p>
        <ul>
          <li>Первый этап — онлайн-голосование через сайт Премии, где каждый член жюри выставляет баллы заявкам в своих профессиональных категориях.</li>
          <li>Второй этап — открытое голосование на собрании гильдии, где номинанты обсуждаются и принимается финальное решение.</li>
        </ul>
        <p>5.3. Оценка заявок проводится по следующим критериям:</p>
        <ul>
          <li>Новизна и оригинальность идеи</li>
          <li>Профессионализм и качество исполнения</li>
          <li>Соответствие жанру и формату</li>
          <li>Общее впечатление и эмоциональное воздействие</li>
        </ul>
        <p>5.4. По результатам первого этапа голосования формируется шорт-лист, включающий до семи номинантов в каждой категории.</p>
        <p>5.5. В случае равенства баллов, победитель определяется на основе обсуждения и дополнительного голосования на собрании гильдии.</p>
        <p>5.6. В финале голосования определяется один победитель в каждой номинации.</p>

        <h2>6. Награждение победителей</h2>
        <p>6.1. Победители Премии "Телетриумф" объявляются на официальной церемонии награждения, которая проводится ежегодно 5 апреля.</p>
        <p>6.2. Победители каждой номинации получают статуэтку Премии "Телетриумф", а также Сертификат о победе в соответствующей номинации.</p>
        <p>6.3. Оргкомитет имеет право учреждать специальные призы и награды по согласованию с Украинской телевизионной академией.</p>
        <p>6.4. В случае невозможности личного присутствия на церемонии, награды могут быть вручены доверенному лицу или отправлены по почте.</p>

        <h2>7. Права и обязанности участников</h2>
        <p>7.1. Участники Премии обязуются предоставлять достоверную информацию и оригинальные материалы для участия в конкурсе.</p>
        <p>7.2. Участники соглашаются на использование материалов, представленных на конкурс, в промо-материалах Премии и на официальных ресурсах Оргкомитета.</p>
        <p>7.3. Участники имеют право на защиту своих авторских прав и интересов в рамках законодательства Украины.</p>

        <h2>8. Заключительные положения</h2>
        <p>8.1. Настоящее Положение вступает в силу с момента его утверждения Оргкомитетом Премии.</p>
        <p>8.2. Все изменения и дополнения к Положению утверждаются Оргкомитетом и вступают в силу с момента их опубликования на официальном сайте Премии.</p>
        <p>8.3. В случае возникновения спорных ситуаций, связанных с проведением Премии, решение принимает Оргкомитет в соответствии с настоящим Положением.</p>
        <p>8.4. Оргкомитет оставляет за собой право толковать и вносить уточнения в текст настоящего Положения в зависимости от обстоятельств проведения Премии.</p>
      </div>
    </div>
    </>
  );
};

export default PositionPage;
